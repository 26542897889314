<template>
	<section>
		<h1>Bilan du QCM {{ qcmTitle }}</h1>
		<div class="nav-bar-wrapper">
			<div class="nav-bar">
				<router-link id="ViewQcmNote" :to="{name: 'ViewQcmNote', params:{id:$route.params.id}}">Notes</router-link>
				<router-link id="ViewQcmStats" :to="{name: 'ViewQcmStats', params:{id:$route.params.id}}">Résumé</router-link>
				<router-link id="ViewQcmStatsStudents" :to="{name: 'ViewQcmStatsStudents', params:{id:$route.params.id}}">Réponses étudiants</router-link>
				<span class="underline"></span>
			</div>
		</div>

		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component class="main-component" :is="Component"></component>
			</transition>
		</router-view>
	</section>
</template>
<script>
import { getQCMService } from '../../../../../services/qcmServices'

export default {
	data() {
		return {
			spanWidth: 0,
			spanLeft: 0,
			qcmTitle: ''
		}
	},

	methods: {
		async getQCM() {
			const res = await getQCMService(this.$route.params.id)
			this.qcmTitle = res.title
		}
	},

	created(){
		this.getQCM()
	},
	mounted() {
		this.spanWidth = document.getElementById(this.$route.name).offsetWidth
		this.spanLeft = document.getElementById(this.$route.name).offsetLeft
		document.querySelector('.underline').style.width = `${this.spanWidth}px`
		document.querySelector('.underline').style.left = `${this.spanLeft}px`
	},

	watch: {
		$route(to) {
			if (to.name !== 'ViewQcmNote' && to.name !== 'ViewQcmStats' && to.name !== 'ViewQcmStatsStudents') {
				return 
			}
			
			this.spanWidth = document.getElementById(to.name).offsetWidth
			this.spanLeft = document.getElementById(to.name).offsetLeft
			document.querySelector('.underline').style.width = `${this.spanWidth}px`
			document.querySelector('.underline').style.left = `${this.spanLeft}px`
		}
	}
}
</script>
<style lang="scss" scoped>
h1{
	text-align: center;
	justify-content: center;
}

.nav-bar-wrapper{
	margin-inline: auto;
	padding-inline: 8rem;
	width: 100%;

}
.nav-bar{
	display: flex;
	gap: 4rem;
	font-size: 1.8rem;
	margin-top: 8rem;  
	font-weight: 600;
	position: relative;
	margin-inline: auto;
	width: 60%;

	&::before{
		content: '';
		position: absolute;
		bottom: -1rem;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: #fefefe;
	}

	.underline{
		position: absolute;
		bottom: calc(-1rem - 1.5px);
		left: 0;
		height: 5px;
		background-color: #3732AF;
		transition: 0.4s;
		border-radius: 5px;
	}

	a{
		text-decoration: none;
		color: black;
		transition: .4s;
	}
}
</style>
